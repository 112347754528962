<template>
  <div id="app">
    <defaultHeader></defaultHeader>
    <router-view />
    <defaultFooter></defaultFooter>
  </div>
</template>
<script>
import defaultHeader from "@/components/public/header";
import defaultFooter from "@/components/public/footer";
import { mapGetters } from "vuex";
export default {
  components: {
    defaultHeader,
    defaultFooter
  }
};
</script>
<style lang="scss"></style>
