import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/common.scss";
import "iview/dist/styles/iview.css";
import VueClipboard from "vue-clipboard2";
import elementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"
Vue.config.productionTip = false;
// import { codemirror } from 'vue-codemirror'
Vue.use(VueClipboard);
Vue.use(elementUI);
// import "codemirror/lib/codemirror.css"

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");
