<template>
  <footer class="footer-box">
    <div class="footer-container">
      <div class="w1200">
        <div class="footer-logo">
          <img :src="require('@/assets/images/footer-logo.png')" alt="" />
        </div>
        <div class="footer-service">
          <div class="service-item">
            <p class="service-title">业务介绍</p>
            <p class="service-item-text">
              <router-link to="/product">产品介绍</router-link>
            </p>
          </div>
          <div class="service-item">
            <p class="service-title">关于我们</p>
            <p class="service-item-text">
              
              <router-link to="/about">公司介绍</router-link>
            </p>
          </div>
          <div class="service-item">
            <p class="service-title">投诉与建议</p>
            <p class="service-item-text">
              <img width="16" height="12" src="../../assets/images/home/email-icon.png" alt="">
              <a href="mailto:Carl.zhu@tofba.com">Carl.zhu@tofba.com</a>
            </p>
          </div>
          <div class="service-item">
            <p class="service-title">联系我们</p>
            <p class="service-item-text phone">
              <img width="17" height="16" src="../../assets/images/home/phone-icon.png" alt="">
              <span>13728680552</span>
              </p>
            <p class="service-item-text">
              
              <img width="15" height="19" src="../../assets/images/home/qq-icon.png" alt="">
              <a
                href="http://wpa.qq.com/msgrd?v=3&amp;uin=1844266934&amp;site=qq&amp;menu=yes"
                target="_blank"
                >1844266934</a
              >
            </p>
          </div>
          <div class="service-item">
            <img
              class="wechat-qr"
              :src="require('@/assets/images/weixin.jpg')"
              alt=""
            />
            <p class="wechat-qr-text">关注官方公众号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-beian">
      <div class="w1200">
        © Copyright 2019-2023 Reserved
        <a target="_blank" href="https://beian.miit.gov.cn"
          >粤ICP备15066393号-4</a
        >
        <a
          style="display: inline-flex"
          href="https://www.cnzz.com/stat/website.php?web_id=1279761396"
          target="_blank"
          title="站长统计"
          ><img
            border="0"
            hspace="0"
            vspace="0"
            src="https://icon.cnzz.com/img/pic.gif"
        /></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "default-footer",
  mounted() {
    // document.getElementById("cnzz_stat_icon_1279761396").style.display = "none";
  },
};
</script>

<style lang="scss" scoped>
.footer-box {
  .footer-container {
    overflow: hidden;
    height: 436px;
    background: #252525;
  }
  .nav-beian {
    height: 58px;
    line-height: 58px;
    background-color: #303030;
    text-align: center;
    color: #c4c4c4;
    a {
      color: #c4c4c4;
    }
  }
  .footer-logo {
    margin-top: 74px;
    width: 171px;
    height: 41px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .footer-service {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 56px;
    .service-item {
      margin-right: 90px;
      &:last-child {
        margin-left: 155px;
        text-align: center;
        .wechat-qr {
          width: 130px;
          height: 130px;
          box-sizing: border-box;
          border-radius: 7px;
        }
        .wechat-qr-text {
          margin-top: 10px;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .service-title {
        font-size: 16px;
        font-family: SourceHanSansCN-Bold;
        color: #ffffff;
        margin-bottom: 34px;
      }
      &-text {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 19px;
        img{
          margin-right: 10px;
        }
        &.phone {
          cursor: text;img{
          margin-right: 8px;
        }
        }
        a {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
