<template>
  <div class="nav-list">
    
      <router-link to="/" class="nav-item" v-if="$route.path!='/'">首页</router-link>
              <router-link class="nav-item" to="/product">产品介绍</router-link>
      
      <router-link class="nav-item" to="/about">公司介绍</router-link>
      <el-popover placement="top-start" title="" trigger="hover">
        <span slot="reference">
          <div class="nav-item">联系我们</div>
        </span>
        <div>
          <p><span class="label">电话：</span>13728680552</p>
          <p><span class="label">QQ：</span>
              <a
                href="http://wpa.qq.com/msgrd?v=3&amp;uin=1844266934&amp;site=qq&amp;menu=yes"
                target="_blank"
                style="color: #606266;" >1844266934</a
              ></p>
          <p><span class="label">投诉与建议：</span>
              <a href="mailto:Carl.zhu@tofba.com" style="color: #606266;">Carl.zhu@tofba.com</a></p>
          <p>
            <span class="label" style="vertical-align: top">公众号：</span>
            <img
              :src="require('@/assets/images/weixin.jpg')"
              style="width: 100px; height: 100px"
              alt=""
            />
          </p>
        </div>
      </el-popover>
      
      <router-link class="nav-item" to="/api-document">API文档</router-link>
    <div class="nav-item register">
      <el-button type="primary" size="small">
        <a href="https://www.lmparcel.com/lmcustomerlogin/login" target="_blank"
          >注册发货</a
        >
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "default-nav",
  data() {
    return {
      isShowContact: false,
    };
  },
  mounted() {
    
  },
  methods: {
    onChange() {
      this.isShowContact = !this.isShowContact;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  line-height: 80px;
  .nav-item {
    padding: 0 24px;
    text-align: center;
    cursor: pointer;
      color: #686868;
    a,
    span {
      color: #686868;
    }
    &.register ::v-deep .el-button {
      background-color: #d33f69;
      border-color: #d33f69;
      border-radius: 4px;
      a {
        color: #fff;
      }
    }
  }
}
</style>
