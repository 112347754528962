<template>
  <div class="header-box fixed-top">
    <nav class="w1200 nav-header">
      <router-link to="/" class="logo"> <img :src="logo" alt="" /></router-link>
      <navBox></navBox>
    </nav>
  </div>
</template>

<script>
import logo from "@/assets/images/logo.png";

// import avatar from "./com/avatar";  // 暂时不需要
import navBox from "./com/nav";
export default {
  name: "defaultHeader",
  data() {
    return {
      logo,
    };
  },
  components: {
    navBox,
    // avatar
  },
};
</script>

<style lang="scss" scoped>
.header-box {
  height: 80px;
  width: 100%;
  background: linear-gradient(0deg, #fffbfc 0%, #ffffff 100%);
  box-shadow: 2px 6px 4px 0px rgba(0, 53, 107, 0.08);
  position: fixed;
  top: 0;
  z-index: 11;
  // .fixed-top {
  //   box-shadow: 0 2px 6px 0 rgb(19 32 52 / 12%);
  //   background-color: #fff;
  // }
  .logo {
    img {
      width: 160px;
      height: auto;
    }
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
