const routes = [
  {
    path: "/",
    name: "home",
    meta:{
      title: '首页'
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/home.vue")
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "@/views/search/search.vue")
  },
  {
    path: "/about",
    name: "about",
    meta:{
      title: '公司介绍'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/about.vue")
  },
  {
    path: "/product",
    name: "product",
    meta:{
      title: '产品介绍'
    },
    component: () =>
      import(/* webpackChunkName: "product" */ "@/views/product/product.vue")
  },
  {
    path: "/api-document",
    name: "api-document",
    component: () =>
      import(/* webpackChunkName: "api" */ "@/views/api-doc/index.vue")
  },
  {
    path: "*",
    redirect: '/'
  }
];
export default routes;
