import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./router";
import ViewUI from "view-design";
Vue.use(VueRouter);
Vue.use(ViewUI);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
});

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();
  next();
});

router.afterEach(route => {
  ViewUI.LoadingBar.finish();
});

export default router;
